// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'
import { errorToast, sucessToast } from '../../../components/messageToast/messageToast'
// ** Axios Imports
import axios from 'axios'
const qs = require('qs')
// import { errorToast, sucessToast } from '../../../components/messageToast/messageToast'
// const qs = require('qs')
export const getAllData = createAsyncThunk('appPayments/getAllData', async (params) => {
  const instanceGet = axios.create({
    baseURL: urls.url,
    timeout: 15000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceGet.get(`/customers/payments_details/`, {params: {p:params.page, idCustomer:params.idCustomer, id:params.document, start:params.start, end:params.end, method:params.method}})
  console.log(response)
  return response
})

export const getPayment = createAsyncThunk('appPayments/getPayment', async id => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 15000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  
  const response = await instance.get(`/customers/payments_details/${id}/`)
  return response.data
})

export const updatePayment = createAsyncThunk('appPayments/updatePayment', (params, { dispatch }) => {
  const instance2 = axios.create({
    baseURL: urls.url,
    timeout: 15000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/x-www-form-urlencoded"}
  })
  instance2.post(`/customers/payments_details/update_payment/`, qs.stringify(params.payment))
    .then(async resp => {
      await dispatch(getPayment(params.payment.id))
      sucessToast("Pago se modifico correctamente")
      console.log(resp.data)
      return resp.data
    })
    .catch((err) => {
      if (err?.response?.data?.error) {
        errorToast(err.response.data.msg)
      } else {
        errorToast("Pago no se modifico correctamente")
      }
    })
})


export const deletePayment = createAsyncThunk('appPayments/deletePayment', (params, { dispatch }) => {
  const instance2 = axios.create({
    baseURL: urls.url,
    timeout: 15000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/x-www-form-urlencoded"}
  })
  instance2.post(`/customers/payments_details/delete_payment/`, qs.stringify({id_payment_detail:params.id_payment_detail}))
    .then(async resp => {
      sucessToast("Pago se elimino correctamente")
      await dispatch(getAllData(params.getData))
      return resp.data
    })
    .catch((err) => {
      if (err?.response?.data?.error) {
        errorToast(err.response.data.msg)
      } else {
        errorToast("Pago no se elimino correctamente")
      }
    })
})

export const cleanPayments = createAsyncThunk('appPayments/cleanPayments', () => {
  return "clean"
})

export const appPaymentsSlice = createSlice({
  name: 'appPayments',
  initialState: {
    allData: [],
    total: 1,
    selectedPayment: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        console.log(action)
        state.allData = action.payload.data.results
        state.total = action.payload.data.count
      })
      .addCase(getPayment.fulfilled, (state, action) => {
        console.log(action.payload)
        state.selectedPayment = action.payload
      })

      .addCase(cleanPayments.fulfilled, (state) => {
        state.allData = []
        state.total = 1
      })

  }
})

export default appPaymentsSlice.reducer
