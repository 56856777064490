// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'
// ** Axios Imports
import axios from 'axios'
import { errorToast, sucessToast } from '../../../../components/messageToast/messageToast'


const instance = axios.create({
  baseURL: urls.url,
  timeout: 15000,
  headers: {Authorization: "JWT " + localStorage.getItem("token"), "Content-Type": "application/json"}
})

export const getFiles = createAsyncThunk('appFiles/getFiles', async (params) => {
  const response = await instance.get(`/files/`, {params: {...params}})
  console.log(response)
  return response
})


export const addFile = createAsyncThunk('appFiles/addFile', async (params, { dispatch, getState }) => {
  instance.post('/files/', params)
    .then(async resp => {
      await dispatch(getFiles(getState().files.params))
      sucessToast(resp.data?.msg ?? "Archivo añadido correctamente")
      return resp.data
    })
    .catch((e) => {
      console.log(e.response?.data)
      errorToast(e.response?.data?.msg ?? 'Hubo problemas, intente nuevamente en unos minutos')
    })
})


export const deleteFile = createAsyncThunk('appFiles/deleteFile', async (id, { dispatch, getState }) => {
  await instance.delete(`/files/${id}/`).then(async resp => {
    await dispatch(getFiles(getState().files.params))
    sucessToast(resp.data?.msg ?? "Archivo eliminado correctamente")
    return id
  })
  .catch((e) => {
    console.log(e.response.data)
    errorToast(e.response.data?.msg ?? 'Hubo problemas, intente nuevamente en unos minutos')
    return id
  })
})


export const cleanFiles = createAsyncThunk('appFiles/cleanFiles', () => {
  return "clean"
})


export const appFilesSlice = createSlice({
  name: 'appFiles',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selectedCustomer: null,
    selectedContact: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getFiles.fulfilled, (state, action) => {
        state.data = action.payload.data ?? []
        state.total = action.payload.data.count
        state.params = action.meta.arg
      }).addCase(cleanFiles.fulfilled, (state) => {
        state.data = []
        state.total = 1
        state.params = {}
      })
  }
})

export default appFilesSlice.reducer
