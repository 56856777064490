// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'
// ** Axios Imports
import axios from 'axios'
import { errorToast, sucessToast } from '../../../components/messageToast/messageToast'


export const getAllUsers = createAsyncThunk('appUsers/getAllUsers', async (params) => {
  const instanceGet = axios.create({
    baseURL: urls.url,
    timeout: 15000,
    headers: {Authorization: "JWT " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceGet.get(`/profiles/`, {params: {...params}})
  console.log(response)

  return response
})


export const addUser = createAsyncThunk('appUsers/addUser', async (params, { dispatch }) => {
  console.log(params)
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 15000,
    headers: {Authorization: "JWT " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  
  instance.post('/profiles/', params)
    .then(async resp => {
      await dispatch(getAllUsers())
      sucessToast("Usuario añadido correctamente")
      return resp.data
    })
    .catch((e) => {
      console.log(e.response.data)
      errorToast(e.response.data?.msg ?? 'Hubo problemas, intente nuevamente en unos minutos')
    })
  
})


export const addAssistantRelation = createAsyncThunk('appUsers/addAssistantRelation', async (params, { dispatch }) => {
  console.log(params)
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 15000,
    headers: {Authorization: "JWT " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })

  instance.post('/profiles/assistant/', params)
    .then(async resp => {
      await dispatch(getAllUsers())
      sucessToast("Asistente añadido correctamente")
      return resp.data
    })
    .catch((e) => {
      console.log(e.response.data)
      errorToast(e.response.data?.msg ?? 'Hubo problemas, intente nuevamente en unos minutos')
    })

})


export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch}) => {
  await axios.delete(`/profiles/${id}/`)
  await dispatch(getAllData({page:1, query:""}))
  return id
})


export const cleanUsers = createAsyncThunk('appUsers/cleanUsers', () => {
  return "clean"
})

export const setLawyer = createAsyncThunk('appUsers/setLawyer', async (lawyer) => {
  return lawyer
})


export const getPermissions = createAsyncThunk('appUsers/getPermissions', async (params) => {

  const instance = axios.create({
    baseURL: urls.url,
    timeout: 15000,
    headers: {Authorization: "JWT " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })


  const response = await instance.get(`/permissions/`, {params: {...params}})

  return response

})

export const getNotifications = createAsyncThunk('appUsers/getNotifications', async (params) => {

  const instance = axios.create({
    baseURL: urls.url,
    timeout: 15000,
    headers: {Authorization: "JWT " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })


  const response = await instance.get(`/notifications/`, {params: {...params}})

  return response

})


export const getMembership = createAsyncThunk('appUsers/getMembership', async (params) => {

  const instance = axios.create({
    baseURL: urls.url,
    timeout: 15000,
    headers: {Authorization: "JWT " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })

  const response = await instance.get(`/memberships/check/`, {params: {...params}})

  return response
})


export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    allData: [],
    total: 1,
    selectedCustomer: null,
    selectedContact: null,
    lawyer: null,
    permissions: null,
    notifications: null,
    membership: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllUsers.fulfilled, (state, action) => {
        console.log(action)
        state.allData = action.payload.data ?? []
        state.total = action.payload.data.count
      }).addCase(cleanUsers.fulfilled, (state) => {
        state.allData = []
        state.total = 1
      }).addCase(setLawyer.fulfilled, (state, action) => {
        state.lawyer = action.payload
      }).addCase(getPermissions.fulfilled, (state, action) => {
        state.permissions = action.payload?.data ?? null
      }).addCase(getNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload?.data ?? null
      }).addCase(getMembership.fulfilled, (state, action) => {
        state.membership = action.payload.data ?? null
      })
  }
})

export default appUsersSlice.reducer
