// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'
// ** Axios Imports
import axios from 'axios'
import { errorToast, sucessToast } from '../../../components/messageToast/messageToast'

export const getAllCustomers = createAsyncThunk('appCustomers/getAllCustomers', async (params) => {
  const instanceGet = axios.create({
    baseURL: urls.url,
    timeout: 15000,
    headers: {Authorization: "JWT " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceGet.get(`/customers/`, {params: {...params}})
  return response
})


export const addCustomer = createAsyncThunk('appCustomers/addCustomer', async (params, { dispatch }) => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 15000,
    headers: {Authorization: "JWT " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  instance.post('/customers/', params)
    .then(async resp => {
      await dispatch(getAllCustomers())
      sucessToast("Cliente añadido correctamente")
      return resp.data
    })
    .catch((e) => {
      console.log('addCustomer -> error')
      console.log(e)
      console.log(e.response.data)
      errorToast(e.response.data?.msg ?? 'Hubo problemas, intente nuevamente en unos minutos')
    })
})


export const deleteCustomer = createAsyncThunk('appCustomers/deleteCustomer', async (id, { dispatch}) => {
  await axios.delete(`/customers/${id}/`)
  await dispatch(getAllData({page:1, query:""}))
  return id
})

export const cleanCustomers = createAsyncThunk('appCustomers/cleanCustomers', () => {
  return "clean"
})

export const appCustomersSlice = createSlice({
  name: 'appCustomers',
  initialState: {
    allData: [],
    total: 0,
    selectedCustomer: null,
    selectedContact: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllCustomers.fulfilled, (state, action) => {
        console.log(action)
        state.allData = action.payload.data ?? []
        state.total = action.payload.data.count
      }).addCase(cleanCustomers.fulfilled, (state) => {
        state.allData = []
        state.total = 0
      })
  }
})

export default appCustomersSlice.reducer
