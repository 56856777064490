// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'
// ** Axios Imports
import axios from 'axios'
import { errorToast, sucessToast } from '../../../../components/messageToast/messageToast'


const instance = axios.create({
  baseURL: urls.url,
  timeout: 15000,
  headers: {Authorization: "JWT " + localStorage.getItem("token"), "Content-Type": "application/json"}
})

export const getCosts = createAsyncThunk('appCosts/getCosts', async (params) => {
  const response = await instance.get(`/costs/`, {params: {...params}})
  console.log(response)
  return response
})


export const addCost = createAsyncThunk('appCosts/addCost', async (params, { dispatch, getState }) => {
  instance.post('/costs/', params)
    .then(async resp => {
      await dispatch(getCosts(getState().costs.params))
      sucessToast(resp.data?.msg ?? "Gasto añadido correctamente")
      return resp.data
    })
    .catch((e) => {
      console.log(e.response?.data)
      errorToast(e.response?.data?.msg ?? 'Hubo problemas, intente nuevamente en unos minutos')
    })
})


export const deleteCost = createAsyncThunk('appCosts/deleteCost', async (id, { dispatch, getState }) => {
  await instance.delete(`/costs/${id}/`).then(async resp => {
    await dispatch(getCosts(getState().costs.params))
    sucessToast(resp.data?.msg ?? "Gasto eliminado correctamente")
    return id
  })
  .catch((e) => {
    console.log(e.response.data)
    errorToast(e.response.data?.msg ?? 'Hubo problemas, intente nuevamente en unos minutos')
    return id
  })
})


export const cleanCosts = createAsyncThunk('appCosts/cleanCosts', () => {
  return "clean"
})


export const appCostsSlice = createSlice({
  name: 'appCosts',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selectedCustomer: null,
    selectedContact: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCosts.fulfilled, (state, action) => {
        state.data = action.payload.data ?? []
        state.total = action.payload.data.count
        state.params = action.meta.arg
      }).addCase(cleanCosts.fulfilled, (state) => {
        state.data = []
        state.total = 1
        state.params = {}
      })
  }
})

export default appCostsSlice.reducer
